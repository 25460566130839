import { template as template_3d246f3f06ae4ad8860d9eb8002205ba } from "@ember/template-compiler";
const FKLabel = template_3d246f3f06ae4ad8860d9eb8002205ba(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
