import { template as template_f5f6674c6c64411090f545a4f01e8577 } from "@ember/template-compiler";
const FKText = template_f5f6674c6c64411090f545a4f01e8577(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
