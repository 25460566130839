import { template as template_74da73c7de6e41dbb6b47580905d6137 } from "@ember/template-compiler";
const WelcomeHeader = template_74da73c7de6e41dbb6b47580905d6137(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
